import type { Locale } from "./types";

export const locales = [
	"de-AT",
	"de-DE",
	"es-ES",
	"fr-BE",
	"fr-FR",
	"fr-LU",
	"nl-BE",
	"nl-NL",
	"pt-PT",
] as const;

export const languages = ["en", "nl", "fr", "de", "es", "pt"] as const;
export const countries = ["AT", "BE", "DE", "ES", "FR", "LU", "NL", "PT"] as const;
export const salutations = ["M", "F", "U"] as const;

export const countryDefaults: { [key: string]: Locale } = {
	AT: "de-AT",
	BE: "nl-BE",
	DE: "de-DE",
	ES: "es-ES",
	FR: "fr-FR",
	LU: "fr-LU",
	NL: "nl-NL",
	PT: "pt-PT",
};

export const legacyLocales = {
	nl: "nl-nl",
	nl_be: "nl-be",
	fr_be: "fr-be",
	de: "de-de",
	fr: "fr-fr",
	lu: "fr-lu",
	at: "de-at",
	es: "es-es",
	pt: "pt-pt",
};

// and external paths, separated by locale.
export const pathnames = {
	"/": "/",
	"/products/[slug]": "/products/[slug]",

	"/account": "/account",

	"/cart": {
		"nl-NL": "/winkelwagen",
		"nl-BE": "/winkelwagen",
		"de-DE": "/warenkorb",
		"fr-FR": "/panier",
		"fr-BE": "/panier",
		"fr-LU": "/panier",
		"de-AT": "/warenkorb",
		"es-ES": "/carrito",
		"pt-PT": "/carrinho",
	},

	"/checkout": {
		"nl-NL": "/afrekenen",
		"nl-BE": "/afrekenen",
		"de-DE": "/kasse",
		"fr-FR": "/paiement",
		"fr-BE": "/paiement",
		"fr-LU": "/paiement",
		"de-AT": "/kasse",
		"es-ES": "/pago",
		"pt-PT": "/pagamento",
	},

	"/checkout/login": {
		"nl-NL": "/afrekenen/inloggen",
		"nl-BE": "/afrekenen/inloggen",
		"de-DE": "/kasse/anmelden",
		"fr-FR": "/paiement/connexion",
		"fr-BE": "/paiement/connexion",
		"fr-LU": "/paiement/connexion",
		"de-AT": "/kasse/anmelden",
		"es-ES": "/pago/iniciar-sesion",
		"pt-PT": "/pagamento/iniciar-sessao",
	},

	"/checkout/information": {
		"nl-NL": "/afrekenen/informatie",
		"nl-BE": "/afrekenen/informatie",
		"de-DE": "/kasse/informationen",
		"fr-FR": "/paiement/informations",
		"fr-BE": "/paiement/informations",
		"fr-LU": "/paiement/informations",
		"de-AT": "/kasse/informationen",
		"es-ES": "/pago/informacion",
		"pt-PT": "/pagamento/informacao",
	},

	"/checkout/payment": {
		"nl-NL": "/afrekenen/betaling",
		"nl-BE": "/afrekenen/betaling",
		"de-DE": "/kasse/zahlung",
		"fr-FR": "/paiement/paiement",
		"fr-BE": "/paiement/paiement",
		"fr-LU": "/paiement/paiement",
		"de-AT": "/kasse/zahlung",
		"es-ES": "/pago/pago",
		"pt-PT": "/pagamento/pagamento",
	},

	"/checkout/payment/[orderId]": {
		"nl-NL": "/afrekenen/betaling/[orderId]",
		"nl-BE": "/afrekenen/betaling/[orderId]",
		"de-DE": "/kasse/zahlung/[orderId]",
		"fr-FR": "/paiement/paiement/[orderId]",
		"fr-BE": "/paiement/paiement/[orderId]",
		"fr-LU": "/paiement/paiement/[orderId]",
		"de-AT": "/kasse/zahlung/[orderId]",
		"es-ES": "/pago/pago/[orderId]",
		"pt-PT": "/pagamento/pagamento/[orderId]",
	},

	"/checkout/delivery": {
		"nl-NL": "/afrekenen/levering",
		"nl-BE": "/afrekenen/levering",
		"de-DE": "/kasse/lieferung",
		"fr-FR": "/paiement/livraison",
		"fr-BE": "/paiement/livraison",
		"fr-LU": "/paiement/livraison",
		"de-AT": "/kasse/lieferung",
		"es-ES": "/pago/entrega",
		"pt-PT": "/pagamento/entrega",
	},

	"/checkout/confirmation/[orderId]": {
		"nl-NL": "/afrekenen/bevestiging/[orderId]",
		"nl-BE": "/afrekenen/bevestiging/[orderId]",
		"de-DE": "/kasse/bestatigung/[orderId]",
		"fr-FR": "/paiement/confirmation/[orderId]",
		"fr-BE": "/paiement/confirmation/[orderId]",
		"fr-LU": "/paiement/confirmatioun/[orderId]",
		"de-AT": "/kasse/lieferung/[orderId]",
		"es-ES": "/pago/confirmacion/[orderId]",
		"pt-PT": "/pagamento/confirmacao/[orderId]",
	},

	"/search": {
		"nl-NL": "/zoeken",
		"nl-BE": "/zoeken",
		"de-DE": "/suchen",
		"fr-FR": "/rechercher",
		"fr-BE": "/rechercher",
		"fr-LU": "/rechercher",
		"de-AT": "/suchen",
		"es-ES": "/buscar",
		"pt-PT": "/pesquisar",
	},

	"/stores": {
		"nl-NL": "/winkels",
		"nl-BE": "/winkels",
		"de-DE": "/shops",
		"de-AT": "/shops",
		"fr-FR": "/magasins",
		"fr-BE": "/magasins",
		"fr-LU": "/magasins",
		"es-ES": "/tiendas",
		"pt-PT": "/lojas",
	},

	"/stores/[slug]": {
		"nl-NL": "/winkels/[slug]",
		"nl-BE": "/winkels/[slug]",
		"de-DE": "/shops/[slug]",
		"de-AT": "/shops/[slug]",
		"fr-FR": "/magasins/[slug]",
		"fr-BE": "/magasins/[slug]",
		"fr-LU": "/magasins/[slug]",
		"es-ES": "/tiendas/[slug]",
		"pt-PT": "/lojas/[slug]",
	},

	"/product/[slug]": {
		"nl-NL": "/product/[slug]",
		"nl-BE": "/product/[slug]",
		"de-DE": "/product/[slug]",
		"fr-FR": "/product/[slug]",
		"fr-BE": "/product/[slug]",
		"fr-LU": "/product/[slug]",
		"de-AT": "/product/[slug]",
		"es-ES": "/product/[slug]",
		"pt-PT": "/product/[slug]",
	},

	"/faqs": {
		"nl-NL": "/faqs",
		"nl-BE": "/faqs",
		"de-DE": "/faqs",
		"fr-FR": "/faqs",
		"fr-BE": "/faqs",
		"fr-LU": "/faqs",
		"de-AT": "/faqs",
		"es-ES": "/faqs",
		"pt-PT": "/faqs",
	},

	"/faqs/[slug]": {
		"nl-NL": "/faqs/[slug]",
		"nl-BE": "/faqs/[slug]",
		"de-DE": "/faqs/[slug]",
		"fr-FR": "/faqs/[slug]",
		"fr-BE": "/faqs/[slug]",
		"fr-LU": "/faqs/[slug]",
		"de-AT": "/faqs/[slug]",
		"es-ES": "/faqs/[slug]",
		"pt-PT": "/faqs/[slug]",
	},

	"/account/password-reset": "/account/password-reset",
	"/account/password-reset/success": "/account/password-reset/success",
	"/account/login": "/account/login",
	"/account/profile": "/account/profile",
	"/account/profile/billing-address": "/account/profile/billing-address",
	"/account/profile/credentials": "/account/profile/credentials",
	"/account/register": "/account/register",
	"/account/register/success": "/account/register/success",
	"/account/orders": "/account/orders",
	"/account/orders/[id]": "/account/orders/[id]",
	"/account/address": "/account/address",
};
