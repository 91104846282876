import type { StaticSiteConfig } from "./types";

export default {
	country: "FR",
	currency: "EUR",
	defaultLanguage: "fr",
	languages: ["fr"],
	reviewSites: [
		{
			language: "fr",
			key: "kiyoh",
			url: "https://www.kiyoh.com/",
		},
	],
} satisfies StaticSiteConfig;
