import type { StaticSiteConfig } from "./types";

export default {
	country: "NL",
	currency: "EUR",
	defaultLanguage: "nl",
	languages: ["nl"],
	reviewSites: [
		{
			language: "nl",
			key: "kiyoh",
			url: "https://www.kiyoh.com/",
		},
	],
} satisfies StaticSiteConfig;
