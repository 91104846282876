"use client";

import type { GetSiteQuery } from "@commerce-frontend/types";
import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

export type SiteConfig = GetSiteQuery["site"];

export const SiteConfigContext = createContext<SiteConfig | null>(null);

type StoreConfigProviderProps = PropsWithChildren<{ config: SiteConfig | null }>;

export const SiteConfigProvider = ({ children, config }: StoreConfigProviderProps) => (
	<SiteConfigContext.Provider value={config}>{children}</SiteConfigContext.Provider>
);

export const useSiteConfig = () => {
	return useContext(SiteConfigContext);
};
