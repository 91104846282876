"use client";

import {
	Link as IntlLink,
	isValidLocaleParam,
	prefixForLocale,
} from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import type { pathnames } from "@commerce-shared/site-config";
import NextLink from "next/link";
import { type ComponentProps, useState } from "react";

type Pathnames = keyof typeof pathnames;

export type LinkProps = Omit<ComponentProps<typeof IntlLink<Pathnames>>, "href" | "locale"> & {
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	href: string | ComponentProps<typeof IntlLink<Pathnames>>["href"];
	newTab?: boolean;
	"data-testid"?: string;
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	locale?: string;
};

// Then use the modified type in your component
export const Link = ({ href, newTab, locale, ...rest }: LinkProps) => {
	const [prefetch, setPrefetch] = useState(false);

	let prefetchTimeout: NodeJS.Timeout | null = null;
	const prefetchArgs = {
		onMouseEnter: () => {
			prefetchTimeout = setTimeout(() => {
				setPrefetch(true);
			}, 150);
		},
		onMouseLeave: () => {
			if (prefetchTimeout) {
				clearTimeout(prefetchTimeout);
				prefetchTimeout = null;
			}
			setPrefetch(false);
		},
		onTouchStart: () => setPrefetch(true),
		onTouchEnd: () => setPrefetch(false),
	};

	if (typeof href === "string") {
		const isExternal = /^https?:/.test(href);

		let localePrefix = undefined;
		if (locale && isValidLocaleParam(locale)) {
			localePrefix = prefixForLocale(locale);
		}

		if (localePrefix) {
			return (
				<NextLink
					{...rest}
					href={href}
					target={newTab || isExternal ? "_blank" : undefined}
					rel={newTab || isExternal ? "nofollow noopener noreferrer" : undefined}
					locale={localePrefix}
				/>
			);
		}

		return (
			<NextLink
				{...rest}
				{...prefetchArgs}
				prefetch={prefetch}
				href={href}
				target={newTab || isExternal ? "_blank" : undefined}
				rel={newTab || isExternal ? "nofollow noopener noreferrer" : undefined}
			/>
		);
	}

	// We can't prefetch when a locale is defined, so forcefully skip that
	if (locale) {
		return <IntlLink {...rest} href={href} locale={(locale || undefined) as Locale | undefined} />;
	}

	return <IntlLink {...rest} prefetch={prefetch} {...prefetchArgs} href={href} />;
};
