import(/* webpackMode: "eager" */ "/app/frontend/packages/ui/src/primitives/Link/Link.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/image-loader.ts");
;
import(/* webpackMode: "eager", webpackExports: ["BackButton"] */ "/app/frontend/site/src/app/[locale]/(simple-layout)/_components/SimpleHeader/_components/BackButton/BackButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GTMClickEvent"] */ "/app/frontend/site/src/components/GoogleAnalytics/GTMClickEvent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/app/frontend/site/src/components/Image/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteConfigProvider"] */ "/app/frontend/site/src/lib/site-config-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.15.2_next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+te_0c4fe260f6ef0a4d7f2fd28a786cd267/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.15.2_next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+te_0c4fe260f6ef0a4d7f2fd28a786cd267/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/image-component.js");
