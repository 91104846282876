import {
	pathnames as configPathnames,
	getLocalePrefix,
	locales,
} from "@commerce-shared/site-config";
import { createLocalizedPathnamesNavigation } from "next-intl/navigation";
import type { LocalePrefix, Pathnames } from "next-intl/routing";
import type { Locale } from "./types";

export const localePrefix = {
	mode: "always",
	prefixes: {
		// Use the `getLocalePrefix` method, ensuring the front-end and back-end are in sync.
		"nl-NL": getLocalePrefix("NL", "nl"),
		"nl-BE": getLocalePrefix("BE", "nl"),
		"de-DE": getLocalePrefix("DE", "de"),
		"fr-FR": getLocalePrefix("FR", "fr"),
		"fr-BE": getLocalePrefix("BE", "fr"),
		"fr-LU": getLocalePrefix("LU", "fr"),
		"de-AT": getLocalePrefix("AT", "de"),
		"es-ES": getLocalePrefix("ES", "es"),
		"pt-PT": getLocalePrefix("PT", "pt"),
	},
} satisfies LocalePrefix<typeof locales>;

const pathnames = configPathnames satisfies Pathnames<typeof locales>;

/**
 * Retrieves the vanity label for the provided locale.
 * E.g. passing in nl-NL will result in the label "nl-nl" being returned
 * @param locale the target locale
 * @returns the vanity label for the provided locale
 */
export const prefixForLocale = (locale: Locale): string => {
	return localePrefix.prefixes[locale].substring(1);
};

/**
 * Validates wether a string is a valid locale, affirming it's type for typescript
 * @param locale a string representing a locale
 * @returns true, if the string is a valid locale, false otherwise
 */
export const isValidLocaleParam = (locale: string): locale is Locale =>
	(locales as readonly string[]).includes(locale);

/**
 * Used for type casting the language param to a locale
 * @param localeParam a locale parameter value provided by next-intl
 * @returns The same string but typed as a locale
 */
export const getLocaleFromParams = (localeParam: string, defaultLocale: Locale): Locale =>
	isValidLocaleParam(localeParam) ? localeParam : defaultLocale;

export const { Link, redirect, usePathname, useRouter, getPathname } =
	createLocalizedPathnamesNavigation({
		locales,
		localePrefix,
		pathnames,
	});

export const makeAbsolutePath = (locale: Locale, pathname: string): string => {
	const prefix = localePrefix.prefixes[locale];
	if (pathname.startsWith(prefix)) {
		return pathname;
	}
	return `${prefix}${pathname}`;
};
