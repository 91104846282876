"use client";

import type {
	ClickData,
	ClickEventData,
	ContentBlockClickEventData,
} from "@commerce-frontend/gtm-ecommerce";
import { usePathname } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import { useLocale } from "next-intl";
import { useEffect } from "react";
import { useNavigation } from "~/lib/navigation-provider";
import { deviceData, locationDataFromPathname, sendGTMEvent, userData } from "~/lib/send-gtm-event";

export const GTMClickEvent = () => {
	const locale = useLocale() as Locale;
	const pathname = usePathname();
	const { previousUrl } = useNavigation();

	const getClickLocation = (
		element: HTMLButtonElement | HTMLAnchorElement,
	): ClickData["general"]["click_location"] => {
		if (element.closest("main")) {
			return "main content";
		}
		if (element.closest("footer")) {
			return "footer";
		}
		if (element.closest("header")) {
			return "header";
		}
		return null;
	};

	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			const target = event.target as HTMLButtonElement | HTMLAnchorElement | null;

			const clickableElement = target?.closest("a, button") as
				| HTMLButtonElement
				| HTMLAnchorElement;

			const storyblokComponentId = clickableElement?.getAttribute("data-component-id");

			if (clickableElement) {
				sendGTMEvent<ClickEventData>({
					event: "click",
					general: {
						click_element: clickableElement.tagName.toLowerCase(),
						click_id: clickableElement.id || storyblokComponentId || null,
						click_location: getClickLocation(clickableElement),
						click_text: clickableElement.innerText,
						click_url: clickableElement instanceof HTMLAnchorElement ? clickableElement.href : null,
					},
					timestamp: Date.now(),
					device: deviceData(),
					user: userData(),
					location: locationDataFromPathname({
						locale,
						pathname,
						previousUrl,
					}),
				});

				const heroElement = clickableElement.closest('[data-component-type="hero"]') as HTMLElement;

				if (heroElement) {
					const heroElements = Array.from(
						document.querySelectorAll('[data-component-type="hero"]'),
					);
					const heroIndex = heroElements.indexOf(heroElement);
					const componentId = heroElement.getAttribute("data-component-id");

					sendGTMEvent<ContentBlockClickEventData>({
						event: "content_block_click",
						general: {
							campaign_type: null, // @TODO: No data for the campaign type yet, need to add a way to identify campaigns
						},
						banner: {
							text: clickableElement.innerText,
							link: clickableElement instanceof HTMLAnchorElement ? clickableElement.href : null,
							position: heroIndex + 1,
							element: getClickLocation(clickableElement),
							id: componentId ?? null,
						},
						timestamp: Date.now(),
						device: deviceData(),
						user: userData(),
						location: locationDataFromPathname({
							locale,
							pathname,
							previousUrl,
						}),
					});
				}
			}
		};

		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	}, [pathname]);

	return null;
};
