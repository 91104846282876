import { pathnames, staticSiteConfigs } from ".";
import type { Country, Language, Locale } from "./types";

/**
 * Parses a locale into it's country and locale elements
 * @param locale a locale to unpack
 * @returns [Language, Country] - An array containing the language and country parts of the locale
 */
export const unpackLocale = (locale: Locale): [Language, Country] =>
	locale.split("-") as [Language, Country];

/**
 * Generates the locale prefix for the provided country and locale.
 *
 * If no static site config exists, an empty prefix is returned. Paths without prefix
 * will be automatically redirected to the default locale by the frontend middleware.
 * In the case of Zeeman, these are all lowercase two-part locale codes.
 * For example;
 * - a country of "NL" and a language of "nl" will result in a prefix of "/nl-nl".
 * - a country of "BE" and a language of "nl" will result in a prefix of "/nl-be".
 * - ...etc
 *
 * This prefix generator is used by the front-end and back-end alike, to ensure consistent
 * localization prefixing.
 *
 * @returns The prefix for the specified locale, as expected by the front-end.
 */
export const getLocalePrefix = (country: Country, language: Language): string => {
	const siteConfig = staticSiteConfigs.find((c) => c.country === country);

	if (!siteConfig) {
		return "";
	}

	return `/${language.toLowerCase()}-${country.toLowerCase()}`;
};

export const getLocalizedUrl = (host: string, locale: Locale): string | undefined => {
	const [language, country] = unpackLocale(locale);
	const localePrefix = getLocalePrefix(country, language);
	if (!localePrefix) {
		return undefined;
	}

	return `${host}${localePrefix}`;
};

export const getLocalizedUrlWithParameters = (
	host: string,
	pathname: string,
	locale: Locale,
	params: Record<string, string> = {},
): string | undefined => {
	const localizedUrl = getLocalizedUrl(host, locale);

	//@ts-expect-error - We allow any here because upstream can't deal with pathnames being typed
	if (!pathnames[pathname]) {
		return undefined;
	}

	let translatedPageName: string | undefined;
	//@ts-expect-error - We allow any here because upstream can't deal with pathnames being typed
	if (typeof pathnames[pathname] === "object") {
		//@ts-expect-error - We allow any here because upstream can't deal with pathnames being typed
		translatedPageName = pathnames[pathname][locale] as string;
	} else {
		//@ts-expect-error - We allow any here because upstream can't deal with pathnames being typed
		translatedPageName = pathnames[pathname] as string;
	}
	if (!translatedPageName) {
		return undefined;
	}

	return `${localizedUrl}${replaceParams(translatedPageName, params)}`;
};

export const replaceParams = (urlTemplate: string, params: Record<string, string>): string =>
	Object.entries(params).reduce((acc, [key, value]) => acc.replace(`[${key}]`, value), urlTemplate);
