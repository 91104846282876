import {
	type CartFragment,
	type CartLineItemUpdateInput,
	type CartLineItemsUpdateMutation as CartLineItemsUpdateMutationType,
	type CartLineItemsUpdateMutationVariables,
	type Maybe,
	graphql,
} from "@commerce-frontend/types";
import type { GqlResponse } from "@labdigital/graphql-fetcher";
import { useClientGqlFetcher } from "@labdigital/graphql-fetcher";
import {
	type UseMutateAsyncFunction,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useParams } from "next/navigation";
import { useCallback, useEffect, useRef, useState } from "react";
import { getJWT } from "~/lib/helpers/auth";
import { useStoreConfig } from "~/lib/store-config/context";
import { useSafeServerLoading } from "~/lib/useSafeServerLoading";

const GetCart = graphql(/* GraphQL */ `
	query GetCart($storeContext: StoreContextInput!) {
		cart(storeContext: $storeContext) {
			...Cart
		}
	}
`);

const CartLineItemsUpdateMutation = graphql(/* GraphQL */ `
	mutation CartLineItemsUpdate(
		$storeContext: StoreContextInput!
		$id: CartIdentifierInput!
		$lineItems: [CartLineItemUpdateInput!]!
	) {
		cartLineItemsUpdate(storeContext: $storeContext, id: $id, lineItems: $lineItems) {
			...Cart
		}
	}
`);

const ProcessPaazlCheckout = graphql(/* GraphQL */ `
	mutation ProcessPaazlCheckout($storeContext: StoreContextInput!) {
		checkoutUpdate(storeContext: $storeContext, processPaazlCheckout: true) {
			...Cart
		}
	}
`);

export const cartQueryKey = ["cart"] as const;

/**
 * Handles all the logic to fetch and mutate the cart with GraphQL
 * @returns
 */
export const useCart = () => {
	const storeConfig = useStoreConfig();
	const client = useQueryClient();
	const gqlClientFetch = useClientGqlFetcher();
	const { language } = useParams();

	const invalidateCache = () => Promise.all([client.invalidateQueries({ queryKey: cartQueryKey })]);

	// TODO: This seems tricky
	const locale =
		storeConfig.locales.find((locale) => locale.startsWith(language as string)) ??
		storeConfig.defaultLocale;

	/**
	 * Most GraphQL mutations return the updated cart, so we update the cache manually
	 * instead of invalidating and refetching the cart after every update
	 */
	const setCartCache = (cart: Maybe<CartFragment>) => {
		client.setQueryData(cartQueryKey, cart ?? null);
	};

	// Fetch the latest version of the cart
	const {
		isLoading,
		isFetching,
		isStale,
		isPending,
		data: cart,
		refetch: refetchCart,
	} = useSafeServerLoading(
		useQuery({
			queryKey: cartQueryKey,
			enabled: !!getJWT(),
			queryFn: async () => {
				const response = await gqlClientFetch(GetCart, {
					storeContext: {
						storeKey: storeConfig.storeKey,
						currency: storeConfig.currency,
						locale,
					},
				});

				return response.data?.cart ?? null;
			},
		}),
	);

	const { isPending: updateLineItemsIsUpdating, mutateAsync: updateLineItemsMutation } =
		useMutation({
			mutationFn: (variables: CartLineItemsUpdateMutationVariables) =>
				gqlClientFetch<CartLineItemsUpdateMutationType, CartLineItemsUpdateMutationVariables>(
					CartLineItemsUpdateMutation,
					variables,
				),
			onSuccess: (response) => {
				setCartCache(response.data?.cartLineItemsUpdate ?? null);
			},
			/**
			 * Optimistically update the cart with the new line item for better UX
			 */
			onMutate: async (_change) => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await client.cancelQueries({ queryKey: cartQueryKey });
				// Snapshot the previous value
				const previousCart: CartFragment | undefined = client.getQueryData(cartQueryKey);

				return { previousCart };
			},
			onError: (_err, _newCart, context) => {
				// Because we use optimistic updates we need to revert the changes when the mutation fails
				setCartCache(context?.previousCart ?? null);
			},
		});

	const { mutateAsync: processPaazlCheckout } = useMutation({
		mutationFn: () =>
			gqlClientFetch(ProcessPaazlCheckout, {
				storeContext: {
					storeKey: storeConfig.storeKey,
					currency: storeConfig.currency,
					locale,
				},
			}),
		onSuccess: (response) => {
			if (response.data?.checkoutUpdate) {
				setCartCache(response.data.checkoutUpdate);
			}
		},
	});

	return {
		isLoading,
		isUpdating: isFetching || updateLineItemsIsUpdating, // Group all loading states
		isStale,
		isPending: isPending || updateLineItemsIsUpdating,
		refetchCart,
		invalidateCache,
		processPaazlCheckout,
		...useCartBase({
			cart: cart ?? undefined,
			updateLineItemsMutation,
		}),
	};
};

type UseCartBaseProps = {
	cart?: CartFragment;
	updateLineItemsMutation: UseMutateAsyncFunction<
		GqlResponse<CartLineItemsUpdateMutationType>,
		unknown,
		CartLineItemsUpdateMutationVariables
	>;
};

/**
 * Base version of the hook that handles the UI actions and sends them off to the GraphQL hook
 * as well as handling the response. This is separated from the main hook so that it can be
 * tested without having to mock a network layer.
 */
export const useCartBase = ({ cart, updateLineItemsMutation }: UseCartBaseProps) => {
	const storeConfig = useStoreConfig();
	const { language } = useParams();

	// TODO: This seems tricky
	const locale =
		storeConfig.locales.find((locale) => locale.startsWith(language as string)) ??
		storeConfig.defaultLocale;

	// group changes through a debounce
	const lineItemQuantities = useRef<Record<string, number>>({});
	const timeout = useRef<NodeJS.Timeout | null>(null);
	const pendingUpdateLineItemsPromises = useRef<
		{ resolve: () => void; reject: (reason?: unknown) => void }[]
	>([]);
	const [cartState, setCartState] = useState(cart);
	useEffect(() => setCartState(cart), [cart]);

	const updateLineItems = useCallback(
		async (lineItems: CartLineItemUpdateInput[], immediate = false): Promise<void> =>
			new Promise((resolve, reject) => {
				pendingUpdateLineItemsPromises.current.push({
					resolve,
					reject,
				});

				if (timeout.current) {
					clearTimeout(timeout.current);
				}

				lineItems.forEach((lineItem) => {
					lineItemQuantities.current[lineItem.id] = lineItem.quantity;
				});

				if (cartState) {
					setCartState({
						...cartState,
						lineItems: cartState.lineItems
							.filter((lineItem) => lineItemQuantities.current[lineItem.id] !== 0)
							.map((lineItem) => ({
								...lineItem,
								quantity: lineItemQuantities.current[lineItem.id] ?? lineItem.quantity,
							})),
					});
				}

				const update = () => {
					if (cartState) {
						const newLineItems = Object.keys(lineItemQuantities.current).map((id) => ({
							id,
							quantity: lineItemQuantities.current[id],
						}));
						lineItemQuantities.current = {};

						updateLineItemsMutation({
							// TODO: Get these from a store config context
							storeContext: {
								locale,
								currency: storeConfig.currency,
								storeKey: storeConfig.storeKey,
							},
							lineItems: newLineItems,
							id: cartState.id,
						})
							.then(() => {
								pendingUpdateLineItemsPromises.current.forEach(({ resolve }) => resolve());
							})
							.catch((err) => {
								pendingUpdateLineItemsPromises.current.forEach(({ reject }) => reject(err));
							})
							.finally(() => {
								pendingUpdateLineItemsPromises.current = [];
							});
					}
				};

				if (immediate) {
					update();
				} else {
					timeout.current = setTimeout(update, 300);
				}
			}),
		[
			lineItemQuantities,
			timeout,
			cartState,
			setCartState,
			updateLineItemsMutation,
			locale,
			storeConfig,
		],
	);

	const removeLineItems = useCallback(
		(lineItemIds: string[]) =>
			updateLineItems(
				lineItemIds.map((id) => ({
					id,
					quantity: 0,
				})),
			),
		[updateLineItems],
	);

	return {
		removeLineItems,
		updateLineItems,
		cart: cartState,
	};
};
