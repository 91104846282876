import type { StaticSiteConfig } from "./types";

export default {
	country: "ES",
	currency: "EUR",
	defaultLanguage: "es",
	languages: ["es"],
	reviewSites: [
		{
			language: "es",
			key: "kiyoh",
			url: "https://www.kiyoh.com/",
		},
	],
} satisfies StaticSiteConfig;
