import type { StaticSiteConfig } from "./types";

export default {
	country: "PT",
	currency: "EUR",
	defaultLanguage: "pt",
	languages: ["pt"],
	reviewSites: [
		{
			language: "pt",
			key: "kiyoh",
			url: "https://www.kiyoh.com/",
		},
	],
} satisfies StaticSiteConfig;
