import type { StaticSiteConfig } from "./types";

export default {
	country: "AT",
	currency: "EUR",
	defaultLanguage: "de",
	languages: ["de"],
	reviewSites: [
		{
			language: "de",
			key: "kiyoh",
			url: "https://www.kiyoh.com/",
		},
	],
} satisfies StaticSiteConfig;
