import type { Country, Locale } from "../types";
import at from "./at";
import be from "./be";
import de from "./de";
import es from "./es";
import fr from "./fr";
import lu from "./lu";
import nl from "./nl";
import pt from "./pt";

import type { StaticSiteConfig } from "./types";

export * from "./types";

export const fallbackLocale: Locale = "nl-NL";

export const staticSiteConfigs = [nl, be, fr, de, at, es, lu, pt] as StaticSiteConfig[];

export const getStaticSiteConfig = (country: Country): StaticSiteConfig =>
	staticSiteConfigs.find((config) => config.country === country) as StaticSiteConfig;
