"use client";

import { usePathname } from "@commerce-frontend/i18n/navigation";
import { Link } from "@commerce-frontend/ui/primitives/Link";
import { ArrowLeftIcon } from "lucide-react";
import { useTranslations } from "next-intl";
import { useCart } from "~/hooks/cart/useCart";

export const BackButton = () => {
	const t = useTranslations("SimpleHeader");
	const pathname = usePathname();
	const path = pathname.endsWith("/confirmation/[orderId]") ? "/" : "/cart";
	const { refetchCart } = useCart();

	const handleClick = async () => {
		if (pathname.endsWith("/payment/[orderId]")) {
			void refetchCart();
		}
	};

	return (
		<Link
			href={{ pathname: path }}
			onClick={handleClick}
			aria-label="back"
			className="flex flex-col text-center"
		>
			<ArrowLeftIcon className="mx-md lg:mx-lg" />
			<span className="mt-xxs text-xs">{t("go-back")}</span>
		</Link>
	);
};
